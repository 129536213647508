import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { persistor, store } from "./redux/store";
import { ToastContainer } from "react-toastify";

//React-toastify
import "react-toastify/dist/ReactToastify.css";

//React-date-range
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_TESTING_DOMAINE}
    clientId={process.env.REACT_APP_TESTING_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_TESTING_AUDIENCE}
    scope="read:current_user update:current_user_metadata"
    useRefreshTokens={true}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <App />
      </PersistGate>
    </Provider>
    ,
  </Auth0Provider>,
);
