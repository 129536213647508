import axios from "axios";
export const serverIp = "https://connect.api.dev.scalens.com";

const httpClientPublic = axios.create({
  baseURL: `${serverIp}/public`,
  withCredentials: true,
});

const httpClientPublicCustomer = axios.create({
  baseURL: `${serverIp}/public`,
  withCredentials: true,
});

const httpClientCustomer = axios.create({
  baseURL: serverIp,
  withCredentials: true,
});

const httpClientGlobal = axios.create({
  baseURL: serverIp,
  withCredentials: true,
});

export {
  httpClientGlobal,
  httpClientCustomer,
  httpClientPublic,
  httpClientPublicCustomer,
};
