import React, { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./header/Header";
import { useAuth0 } from "@auth0/auth0-react";
import SuspenseWrapper from "../utils/SuspenseWrapper/SuspenseWrapper";
// import MenuIntelligence from '../components/MenuIntelligence/MenuIntelligence';

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Intelligence = lazy(() => import("../pages/Intelligence/Intelligence"));
const Datahub = lazy(() => import("../pages/Datahub/Datahub"));
const Callback=lazy(() => import("../pages/callback"));
function Layout() {
  const { isAuthenticated } = useAuth0();
  // const location = useLocation();
  // const menu_intelligence = location.pathname == "/intelligence" ?
  //   <div className={"mt-3"}><MenuIntelligence/></div> : <></>

  return (
    <div className="no-underline text-primary">
      {isAuthenticated && <Header />}
      <div className="main_content">
        {/* {menu_intelligence} */}
        <Routes>
          <Route
            exact
            path="/"
            element={<SuspenseWrapper LazyComponent={Dashboard} />}
          />
          <Route
            path="/intelligence"
            element={<SuspenseWrapper LazyComponent={Intelligence} />}
          />
          <Route
            path="/data-hub"
            element={<SuspenseWrapper LazyComponent={Datahub} />}
          />
          <Route
            path="/callback"
            element={<SuspenseWrapper LazyComponent={Callback} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default Layout;
