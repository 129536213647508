import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAuth0 } from "@auth0/auth0-react";

function UserProfile() {
  const { user } = useAuth0();
  console.log("user: ", user);
  const [firstName, setFirstName] = useState(
    user.given_name ? user.given_name : "",
  );
  const [lastName, setLastName] = useState(user.lastName ? user.lastName : "");
  const [phoneNumber, setPhoneNumber] = useState(
    user.phone_number ? user.phone_number : "",
  );
  const [email, setEmail] = useState(user.email);
  const [companyName, setCompanyName] = useState(
    user.company ? user.company : "",
  );

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Avatar
          alt="Profile Picture"
          src={user.picture ? user.picture : "https://via.placeholder.com/150"}
          sx={{ width: 80, height: 80 }}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="h5">
          {firstName} {lastName}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {companyName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="firstName"
          label="First Name"
          fullWidth
          value={firstName}
          onChange={handleFirstNameChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="lastName"
          label="Last Name"
          fullWidth
          value={lastName}
          onChange={handleLastNameChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="phoneNumber"
          label="Phone Number"
          fullWidth
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="email"
          label="Email"
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="companyName"
          label="Company Name"
          fullWidth
          value={companyName}
          onChange={handleCompanyNameChange}
        />
      </Grid>
    </Grid>
  );
}

export default UserProfile;
