import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect } from "react";
import Layout from "./layouts/Layout";
import { useDispatch } from "react-redux";
import { setCurrentCustomer, setApiToken } from "./redux/user/authSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchDeals } from "./redux/deals/dealsSlice";

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      (async () => {
        const token = await getAccessTokenSilently();
        dispatch(setApiToken(token));
        dispatch(
          setCurrentCustomer(
            user?.["https://api.praexo.com/user_metadata"]?.user_id
          )
        );
        dispatch(fetchDeals());
      })();
    }
  }, [dispatch, user, getAccessTokenSilently, isAuthenticated]);

  return (
    <Router>
      <div className="2xl:w-[80%] xl:w-[84%] md:w-[90%] m-auto">
        <Layout />
      </div>
    </Router>
  );
}

export default App;
