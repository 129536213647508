import React, { useState } from "react";
import { MdNotificationsNone } from "react-icons/md";
import { AiOutlineHome, AiOutlineTeam } from "react-icons/ai";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import LogoutIcon from "@mui/icons-material/Logout";
import RoundedSelect from "../../../components/rounded_select/RoundedSelect";
import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import Login from "../../../pages/Login/Login";

function HeaderRight({ notification }) {
  const [roundedSelectProps, setRoundedSelectProps] = useState({
    modal: null,
    isOpen: false,
  });
  // const LogoTeam = "./images/krakenimages-Y5bvRlcCx8k-unsplash.jpg";
  // const LogoImage = "./images/michael-dam-mEZ3PoFGs_k-unsplash.jpg";

  const { isAuthenticated, error, user, logout } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  // const team = [
  //   {
  //     id: 1,
  //     image: LogoImage,
  //     text: "YAAGOUBI Noureddine",
  //   },
  //   {
  //     id: 2,
  //     image: LogoImage,
  //     text: "YAAGOUBI Noureddine",
  //   },
  //   {
  //     id: 3,
  //     image: LogoImage,
  //     text: "YAAGOUBI Noureddine",
  //   },
  // ];

  const menuItems = [
    {
      icon: (
        <AiOutlineHome
          className="w-[20px] h-[20px] rounded-[50px] ml-[2px] mr-[6px]"
          size={20}
        />
      ),
      text: "Profile",
      onClick: () => setRoundedSelectProps({ modal: "PROFILE", isOpen: true }),
    },
    {
      icon: (
        <AiOutlineTeam
          className="w-[20px] h-[20px] rounded-[50px] ml-[2px] mr-[6px]"
          size={20}
        />
      ),
      text: "Peers",
      onClick: () => setRoundedSelectProps({ modal: "PEERS", isOpen: true }),
    },
    {
      icon: (
        <LogoutIcon
          className="w-[20px] h-[20px] rounded-[50px] ml-[2px] mr-[6px]"
          size={20}
        />
      ),
      text: "Logout",
      onClick: () => logout({ returnTo: window.location.origin }),
    },
  ];

  return (
    <div className="absolute right-0 flex justify-center items-center">
      {isAuthenticated && (
        <>
          <div
            className="relative p-[9px] rounded-full border border-dashed border-[1.7px] border-[#9B9B9B] flex
            justify-center items-center mr-[3%] hover:bg-[#e0e0e0] hover:cursor-pointer"
          >
            <MdNotificationsNone size={20} color="#304376" />
            <span
              className="absolute top-[4px] right-[7px] bg-warning w-3 h-3 flex justify-center items-center
          text-[10px] text-white rounded-3xl"
            >
              {notification}
            </span>
          </div>
          {/* <RoundedSelect
            defaultImage={LogoTeam}
            defaultName="My Team"
            items={team}
          /> */}
        </>
      )}

      {isAuthenticated ? (
        <RoundedSelect
          defaultImage={user.picture}
          defaultName={user.name}
          items={menuItems}
          modal={roundedSelectProps.modal}
          isOpen={roundedSelectProps.isOpen}
          handleClose={() =>
            setRoundedSelectProps({ ...roundedSelectProps, isOpen: false })
          }
        />
      ) : (
        <Login />
      )}
    </div>
  );
}

export default withAuth0(HeaderRight);
