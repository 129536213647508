import React from "react";
import { NavLink } from "react-router-dom";
import { VscHome } from "react-icons/vsc";
import { IoFlashOutline } from "react-icons/io5";

import { RiBarChart2Line } from "react-icons/ri";

function Navbar() {
  return (
    <div className="flex flex-1 w-[50%] items-baseline text-title_bodyFonts text-xs font-[400]">
      <NavLink
        end
        to={"/"}
        className={({ isActive }) =>
          [
            "flex xl:mr-[25px] lg:mr-[20px] md:mr-[15px] hover:bg-backgroundSearchField hover:shadow-md hover:cursor-pointer",
            isActive &&
              "border-b-2 border-[#3498DB] transition duration-300 ease-in-out",
          ]
            .filter(Boolean)
            .join(" ")
        }
      >
        <VscHome size={20} color="#3498DB" />
        <label className="text-[#3498DB] font-semibold mt-[3px] ml-1 mr-0.5 hover:cursor-pointer">
          My Dashboard
        </label>
      </NavLink>
      <NavLink
        to={"/intelligence"}
        className={({ isActive }) =>
          [
            "flex xl:mr-[25px] lg:mr-[20px] md:mr-[15px] hover:bg-backgroundSearchField hover:shadow-md hover:cursor-pointer",
            isActive &&
              "border-b-2 border-[#4834D4] transition duration-300 ease-in-out",
          ]
            .filter(Boolean)
            .join(" ")
        }
      >
        <IoFlashOutline size={20} color="#4834D4" />
        <label className="text-[#4834D4] font-semibold mt-[3px]  ml-1 mr-0.5 hover:cursor-pointer">
          Intelligence
        </label>
      </NavLink>
      <NavLink
        to={"/data-hub"}
        className={({ isActive }) =>
          [
            "flex xl:mr-[25px] lg:mr-[20px] md:mr-[15px] hover:bg-backgroundSearchField hover:shadow-md hover:cursor-pointer",
            isActive &&
              "border-b-2 border-[#52A1BB] transition duration-300 ease-in-out",
          ]
            .filter(Boolean)
            .join(" ")
        }
      >
        <RiBarChart2Line size={20} color="#52A1BB" />
        <label className="text-[#52A1BB] font-semibold mt-[3px]  ml-1 mr-0.5 hover:cursor-pointer">
          Data Hub
        </label>
      </NavLink>
    </div>
  );
}

export default Navbar;
