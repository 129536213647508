import React from "react";
import Navbar from "../Navbar/Navbar";
import HeaderRight from "./HeaderRight/HeaderRight";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

function Header() {
  const { isAuthenticated } = useAuth0();
  const LogoScalens = "../../images/logo/scalens.svg";
  const LogoImage = "../../images/michael-dam-mEZ3PoFGs_k-unsplash.jpg";
  const navigate = useNavigate();

  const user = {
    name: "YAAGOUBI Noureddine",
    image: LogoImage,
  };

  return (
    <div className="relative w-[100%] h-[10%] mt-6 flex items-center">
      {isAuthenticated && (
        <img
          onClick={() => navigate("/")}
          src={LogoScalens}
          alt="logo scalens"
          className="max-w-[200px] 2xl:mr-[50px] xl:mr-[25px] md:mr-[2%] cursor-pointer"
        />
      )}
      {isAuthenticated && <Navbar />}
      <HeaderRight notification="2" user={user} />
    </div>
  );
}

export default Header;
