import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tier: 1,
};

const tierSlice = createSlice({
  name: "tiers",
  initialState,
  reducers: {
    setTier(state, { payload }) {
      state.tier = payload;
    },
  },
});

export const { setTier } = tierSlice.actions;
export default tierSlice.reducer;
