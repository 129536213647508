import React, { useState } from "react";
import axios from "axios";
import { Autocomplete, Button, Chip, Grid, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material"; // Assuming you use this for delete
import { toastSuccessHelper, toastErrorHelper } from "../../Toasts/Toasts";
const API_URL = process.env.REACT_APP_API_URL;
export default function PeersSuggestor() {
  const [peers, setPeers] = useState([]);

  const handleAddPeer = (event, newValue) => {
    setPeers(newValue);
  };

  const handlePeerSubmit = async () => {
    if (peers.length === 0) {
      toastErrorHelper("Suggested peers list is empty");
      return;
    }
    for (const peer of peers) {
      try {
        await axios.post(`${API_URL}/peers/`, { name: peer });
      } catch (e) {
        toastErrorHelper("Error submitting suggested peer");
        return;
      }
    }
    toastSuccessHelper("Peers suggestions submitted");
    setPeers([]);
  };

  return (
    <>
      <div className="w-full h-auto mt-1">
        <h4 className="text-lg text-[#001756] font-bold mb-2">Suggest Peers</h4>
        <p className="text-base text-[#001756] font-medium mb-4">
          We understand the importance of finding the right company for your
          needs. We kindly request you to enter the company's information below.
          This will help us assist you more effectively. Thank you for your
          cooperation:
        </p>

        <div className="mb-4 mt-7">
          <Autocomplete
            multiple
            freeSolo
            options={[]} // Empty because it's freeSolo
            value={peers}
            onChange={handleAddPeer}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="New Peer"
                placeholder="Add multiple peers..."
                className="w-full"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  key={index}
                  onDelete={() =>
                    setPeers(peers.filter((peer) => peer !== option))
                  }
                  {...getTagProps({ index })}
                  className="mr-2 mb-2"
                />
              ))
            }
          />
        </div>

        <div className="w-full h-auto mt-10">
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePeerSubmit}
          >
            Submit Peers
          </Button>
        </div>
      </div>
    </>
  );
}
