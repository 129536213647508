import React from "react";
import PeersSelector from "./PeersSelector";
import PeersSuggestor from "./PeersSuggestor";

function PeersPreferences() {
  return (
    <div className="container">
      <PeersSelector />
      <hr
        style={{
          border: "none",
          height: "1px",
          background: "#d1d1d1",
          width: "90%",
          margin: "40px auto",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        }}
      />
      <PeersSuggestor />
    </div>
  );
}

export default PeersPreferences;
