import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BasicModal = ({
  children,
  open,
  handleClose,
  dialogTitle,
  dialogText,
  width = "70%",
}) => {
  return (
    <Dialog
      fullscreen={+true}
      style={{ backdropFilter: "blur(5px)" }}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "revert",
          width,
          overflow: "auto",
        },
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={false}
      onClose={handleClose}
    >
      <DialogTitle
        style={{ color: "#8e44ad", fontWeight: "bold", fontSize: 21 }}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        {dialogText && (
          <DialogContentText id="alert-dialog-slide-description">
            {dialogText}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "#27ae60", fontWeight: "bold" }}
          onClick={handleClose}
        >
          <ArrowBackIcon style={{ fontSize: 15, fontWeight: "bold" }} /> Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BasicModal;
