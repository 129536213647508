import { useState, useEffect } from "react";
import AutoCompleteSearch from "../../AutoCompleteSearch/AutocompleteSearch";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Button } from "@mui/material";
import { toastSuccessHelper, toastErrorHelper } from "../../Toasts/Toasts";
const API_URL = process.env.REACT_APP_API_URL;
export default function PeersSelector() {
  const [userPeers, setUserPeers] = useState([]);
  const { user } = useAuth0();
  const investigate_user_id =
    user["https://api.praexo.com/user_metadata"]["investigate_user_id"];
  // ------------- API Functions -------------
  const getUserPeers = () => {
    axios({
      method: "get",
      url: `${API_URL}/user/${investigate_user_id}/peers`,
    }).then((response) => setUserPeers(response.data));
  };
  console.log("userPeers: ",userPeers)
  const upsertUserPeers = () => {
    // console.log("updating User peers...");
    axios({
      method: "post",
      url: `${API_URL}/user/${investigate_user_id}/update_peers`,
      data: userPeers.map((peer) => peer.id),
    })
      .then((response) => toastSuccessHelper("peers updated successfully"))
      .catch((e) => toastErrorHelper("Error while updating peers"));
  };
  //-------------Local functions -------------
  const onChange = (event, newValues) => {
    console.log(
      "🚀 ~ file: PeersSelector.js:32 ~ onChange ~ newValues:",
      newValues
    );

    if (newValues.length > 20) {
      toastErrorHelper("You cannot select more than 20 peers");
    } else {
      setUserPeers(newValues);
    }
  };
  // ------------- Side effects -------------

  useEffect(() => {
    getUserPeers();
  }, []);
  return (
    <>
      <div className="w-full h-auto mt-1">
        <h4 className="text-lg text-[#001756] font-bold mb-2">Select Peers</h4>
        <p className="text-base text-[#001756] font-medium mb-4">
          Please customize your preferences for the company news you wish to
          follow:
        </p>
        <div className="flex mb-4">
          <AutoCompleteSearch
            url={`${API_URL}/peers/`}
            selectedValues={userPeers}
            onChange={onChange}
            getOptionLabel={(option) =>
              `${option.name} (${option.isin.substring(0, 2)})`
            }
            isOptionEqualToValue={(option, value) => option.name === value.name && option.isin==value.isin}
          />
        </div>
        <div className="w-full h-auto mt-10">
          <Button variant="contained" onClick={upsertUserPeers}>
            Update Peers
          </Button>
        </div>
      </div>
    </>
  );
}
