import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import datahubReducer from "./reducers/DatahubReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import dealsSlice from "./deals/dealsSlice";
import authSlice from "./user/authSlice";
import tierSlice from "./dataviz/tierSlice";
import anonymousSlice from "./dataviz/anonymousSlice";

const persistConfig = {
  key: "scalens",
  storage,
};

const rootReducer = combineReducers({
  auth: authSlice,
  deals: dealsSlice,
  tier: tierSlice,
  anonymous: anonymousSlice,
  weekMonitoring: dashboardReducer,
  lastFeedbacks: dashboardReducer,
  newsFeeds: dashboardReducer,
  datahubContent: datahubReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
