import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const BasicCard = ({
  children,
  title,
  titleColor = "#001756",
  titleBackground,
  typo = "h6",
  elevation,
  width = "auto",
  fontSize,
  height = "auto",
  fontWeight,
}) => {
  return (
    <Card
      elevation={elevation}
      sx={{
        height,
        flex: 1,
        width,
        overflow: "inherit",
      }}
    >
      <Typography
        style={{
          backgroundColor: titleBackground,
          fontSize,
          fontWeight,
          padding: "5px 10px",
        }}
        variant={typo}
        color={titleColor}
      >
        {title}
      </Typography>
      <CardContent sx={{ padding: 1, paddingBottom: "13px !important" }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default BasicCard;
