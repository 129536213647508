import React, { Suspense } from "react";

const SuspenseWrapper = ({ LazyComponent }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent />
    </Suspense>
  );
};

export default SuspenseWrapper;
