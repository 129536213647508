import React, { useState, useEffect, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

const AutoCompleteSearch = ({
  url,
  selectedValues,
  getOptionLabel,
  isOptionEqualToValue,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useCallback(
    debounce((nextValue) => fetchOptions(nextValue), 300),
    [] // will be created only once initially
  );

  useEffect(() => {
    if (inputValue.length < 2) {
      return undefined;
    }
    debouncedSave(inputValue);
    return debouncedSave.cancel;
  }, [inputValue, debouncedSave]);

  const fetchOptions = async (inputValue) => {
    const response = await axios.get(`${url}?query=${inputValue}`);
    // Remove duplicates
    let uniqueOptions = [];
    let encounteredCombinations = new Set();

    for (let option of response.data) {
      let identifier = `${option.name}-${option.isin}`;
      if (!encounteredCombinations.has(identifier)) {
        encounteredCombinations.add(identifier);
        uniqueOptions.push(option);
      }
    }

    setOptions(uniqueOptions);
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-search"
      multiple
      open={open}
      sx={{ width: "100%", marginTop: "15px" }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={options}
      value={selectedValues}
      loading={inputValue.length >= 2}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Peers"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {inputValue.length >= 2 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteSearch;
