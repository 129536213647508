const initialState = {
  datahubContents: [],
};

const datahubReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "GET_DATAHUB_CONTENT":
      return {
        ...state,
        datahubContents: action.datahubContent,
      };

    default:
      return state;
  }
};

export default datahubReducer;
