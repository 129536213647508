import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import useOutsideClick from "../../hooks/useOutsideClick";
import BasicModal from "../BasicModal/BasicModal";
import UserAlerts from "../UserProfile/UserAlerts";
import UserProfile from "../UserProfile/UserProfile";
import UserPeers from "../UserProfile/UserPeers/Peers";
function RoundedSelect(props) {
  const [isHidden, setIsHidden] = useState(true);

  const handleClickOutside = () => {
    setIsHidden(true);
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <>
      <BasicModal open={props.isOpen} handleClose={props.handleClose}>
        {props.modal == "PROFILE" && <UserProfile />}
        {props.modal === "PEERS" && <UserPeers />}
      </BasicModal>
      <div
        ref={ref}
        onClick={() => setIsHidden(!isHidden)}
        className={
          "relative w-auto bg-backgroundCard text-xs color-title_bodyFonts shadow-md" +
          " cursor-pointer" +
          " rounded-3xl flex" +
          "flex-col px-2 py-3 mr-4 justify-between items-center content-center"
        }
      >
        <div className="flex flex-nowrap justify-evenly items-center">
          {props.defaultImage && (
            <img
              src={props.defaultImage}
              alt="user profile"
              referrerPolicy="no-referrer"
              className="block w-[20px] h-[20px] mr-2 rounded-3xl"
            />
          )}
          <p className="text-xs mr-1 whitespace-nowrap text-center text-title_bodyFonts">
            {props.defaultName}
          </p>
          <FiChevronDown size={15} color="#8e8e8E" />
        </div>
        <div
          className="absolute w-auto h-[auto] bg-backgroundCard ml-[-10px] top-[45px] rounded-2xl z-[999] mt-1 shadow-md"
          hidden={isHidden}
        >
          {props.items.map((item, index) => {
            return (
              <div
                className="relative w-[180px] h-[30px] flex items-center pl-[10px] hover:bg-background py-5"
                key={index}
                onClick={() => item.onClick()}
              >
                {item.icon ||
                  (item.image && (
                    <img
                      src={item.image}
                      alt="default description alt"
                      className="w-[20px] h-[20px] rounded-[50px] ml-[2px] mr-[6px]"
                    />
                  ))}
                <p className="whitespace-normal">{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default RoundedSelect;
