import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import _ from "lodash";
// ---------------------------
import {
  Grid,
  TextField,
  Autocomplete,
  Box,
  Button,
  Typography,
} from "@mui/material";
import BasicCard from "../Card/BasicCard";
import SaveIcon from "@mui/icons-material/Save";
// ---------------------------
const API_URL = process.env.REACT_APP_API_URL;



  function UserAlerts() {
    const { user, getAccessTokenSilently } = useAuth0();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    
    useEffect(() => {
      const storedProfile = localStorage.getItem("userProfile"); // Modifier en conséquence pour le sessionStorage si nécessaire
  
      if (storedProfile) {
        const userProfile = JSON.parse(storedProfile);
        setFirstName(userProfile.firstName || "");
        setLastName(userProfile.lastName || "");
        setCompanyName(userProfile.companyName || "Scalens");
      }
    }, []);
  //-----userAlerts state---------------
  const [userAlert, setUserAlert] = useState({
    report_indices: [],
    agenda_indices: [],
    selected_companies: [],
    selected_countries: [],
  });
  //----ALL VALUES----
  const getMarkets = () => {
    axios({
      method: "get",
      url: `${API_URL}/datahub/markets`,
    }).then((response) =>
      setIndices(response.data.map((indice) => indice.market))
    );
  };
  const getCountries = () => {
    axios({
      method: "get",
      url: `${API_URL}/datahub/countries`,
    }).then((response) =>
      setCountries(response.data.map((country) => country.country_name))
    );
  };
  const getCompanies = () => {
    axios({
      method: "get",
      url: `${API_URL}/dashboard/companies`,
    }).then((response) =>
      setCompanies(response.data.map((company) => company.company_name))
    );
  };
  const [indices, setIndices] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [countries, setCountries] = useState(null);

  // ----HANDLE CHANGES-----
  function handleReportIndicesChange(e, v) {
    setUserAlert({ ...userAlert, report_indices: v });
  }
  function handleAgendaIndicesChange(e, v) {
    setUserAlert({ ...userAlert, agenda_indices: v });
  }
  function handleSelectedCompaniesChange(e, v) {
    setUserAlert({ ...userAlert, selected_companies: v });
  }
  function handleSelectedCountriesChange(e, v) {
    setUserAlert({ ...userAlert, selected_countries: v });
  }
  function saveChanges() {
    updateUserAlert(userAlert);
  }
  //---- get userAlert-------
  async function getUserAlert() {
    const accessToken = await getAccessTokenSilently();
    const resp = await axios.get(`${API_URL}/user/alert`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: { auth0_id: user.sub },
    });
    let comingAlert = resp.data;
    console.log("comingAlert: ", comingAlert);
    if (comingAlert) {
      setUserAlert({ ...userAlert, ...comingAlert });
    }
  }
  //---- update userData----
  async function updateUserAlert(userAlert) {
    const accessToken = await getAccessTokenSilently();
    const resp = await axios.patch(
      `${API_URL}/user/alert`,
      { auth0_id: user.sub, ...userAlert },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (resp.data.res=="SUCCESS"){
      alert(" Alerts updated successfully")
    }
  }
  //---------First time the form renders-----------
  useEffect(() => {
    if (!companies) {
      getCompanies();
    }
  }, [companies]);
  useEffect(() => {
    if (!indices) {
      getMarkets();
    }
  }, [indices]);
  useEffect(() => {
    if (!countries) {
      getCountries();
    }
  }, [countries]);
  useEffect(() => {
    if (
      _.isEqual(userAlert, {
        report_indices: [],
        agenda_indices: [],
        selected_companies: [],
        selected_countries: [],
      })
    ) {
      getUserAlert();
    }
  }, [userAlert]);
  // ----Rendering-----
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Avatar
          alt="Profile Picture"
          src={user.picture}
          sx={{ width: 80, height: 80 }}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="h5">
          {firstName} {lastName}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {companyName}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="h5">My Alerts</Typography>
      </Grid>
      {indices && companies && countries && (
        <>
          <Grid item xs={12}>
            <BasicCard
              elevation={0}
              title="Select the indices for which you wish to receive alerts: Reports"
            >
              <Autocomplete
                id="index-report-select"
                sx={{ width: "100%", color: "#001756", marginTop: ".75em" }}
                multiple
                options={indices}
                autoHighlight
                // getOptionLabel={(option) => option.index}
                value={userAlert.report_indices ? userAlert.report_indices : []}
                // isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: { fontSize: "small" },
                    }}
                  />
                )}
                onChange={handleReportIndicesChange}
              />
            </BasicCard>
          </Grid>
          <Grid item xs={12}>
            <BasicCard
              elevation={0}
              title="Select the indices for which you wish to receive alerts: Agendas"
            >
              <Autocomplete
                id="index-agenda-select"
                sx={{ width: "100%", color: "#001756", marginTop: ".75em" }}
                multiple
                options={indices}
                autoHighlight
                // getOptionLabel={(option) => option.index}
                value={userAlert.agenda_indices ? userAlert.agenda_indices : []}
                // isOptionEqualToValue={(option, value) =>
                //   option.index === value.index
                // }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: { fontSize: "small" },
                    }}
                  />
                )}
                onChange={handleAgendaIndicesChange}
              />
            </BasicCard>
          </Grid>
          <Grid item xs={12}>
            <BasicCard
              elevation={0}
              title="Select the companies for which you want to receive alerts"
            >
              <Autocomplete
                id="companies-select"
                sx={{ width: "100%", color: "#001756", marginTop: ".75em" }}
                multiple
                options={companies}
                autoHighlight
                // getOptionLabel={(option) => option.company_name}
                value={
                  userAlert.selected_companies
                    ? userAlert.selected_companies
                    : []
                }
                // isOptionEqualToValue={(option, value) =>
                //   option.company_name === value.company_name
                // }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: { fontSize: "small" },
                    }}
                  />
                )}
                onChange={handleSelectedCompaniesChange}
              />
            </BasicCard>
          </Grid>
          <Grid item xs={12}>
            <BasicCard
              elevation={0}
              title="Select the countries for which you want to receive alerts"
            >
              <Autocomplete
                id="countries-select"
                sx={{ width: "100%", color: "#001756", marginTop: ".75em" }}
                multiple
                options={countries}
                autoHighlight
                // getOptionLabel={(option) => option.country_name}
                value={
                  userAlert.selected_countries
                    ? userAlert.selected_countries
                    : []
                }
                // isOptionEqualToValue={(option, value) =>
                //   option.country_name === value.country_name
                // }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      style: { fontSize: "small" },
                    }}
                  />
                )}
                onChange={handleSelectedCountriesChange}
              />
            </BasicCard>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{
                width: 200,
                position: "",
              }}
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default UserAlerts;
