import { createSlice } from "@reduxjs/toolkit";
import {
  httpClientCustomer,
  httpClientGlobal,
} from "../../utils/API/httpClient";

const initialState = {
  userToken: null,
  userId: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    setCurrentCustomer(state, { payload }) {
      state.userId = payload;
    },
    setApiToken(state, action) {
      httpClientGlobal.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload}`;
      httpClientCustomer.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload}`;
      state.userToken = action.payload;
    },
  },
});

export const { setCurrentCustomer, setApiToken } = authSlice.actions;
export default authSlice.reducer;
